.header {
    position: relative;
    padding: 17px 0;
    &__logo {
        display: block;
        text-align: center;
        position: relative;
		width: 260px;
		max-width:100%;
        margin: 0 auto 0 0;
        @include b(mobile) {
			margin: 0 auto 15px;
        }
        a {
            display: block;
            transition: opacity .3s ease-in-out;
            text-decoration: none;
            font-size: 0;
            line-height: 0;
            border: 0;
            &:hover {
                opacity: .8;
            }
        }
        img {
            display: block;
            max-width: 100%;
        }
    }
    &__nav {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        @include b(mobile) {
			display:block
        }
        ul {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: stretch;
            list-style-type: none;
            @include b(mobile) {
				margin: 0 auto;
				padding:0;
				text-align:center;
				justify-content: space-around
            }
            .js-dropdown-cont {
            box-shadow:-15px 0 0 0 #fff, 15px 0 0 0 #fff;
            background: #fff;
            }
            li {
                padding: 0;
                margin: 0 30px;
                white-space: nowrap;
                &:before {
                    display: none;
				}
				&:last-child {
					margin-right:0;
				}
				&:first-child {
					margin-left:0;
				}
                @include b(mobile) {
					margin: 0;
                }
                @include b(min) {
                    text-align: center;
                }
                a {
                    font-size: 17px;
                    line-height: 24px;
                    font-weight: 400;
                    border: 0;
                    display: inline-block;
                    position: relative;
                    &:hover {
                        color: #a0c63f;
                    }
                    &.active{
                        color:#a0c63f;
                    }
                }
            }
        }
    }
}

// .header__nav #menu {
//     @include b(960) {
//         background: #fff;
//         display: none;
//         position: fixed;
//         width: 190px;
//         height: 100vh;
//         overflow: visible;
//         right: 0;
//         top: 0;
//         z-index: 99998;
//         padding: 6px 0 0;
//         box-shadow: 0 0 2px 1px #7d939b;
//         &:before {
//             display: none;
//         }
//         &:after {
//             display: block;
//             content: "";
//             width: calc(100vw - 190px);
//             height: 100vh;
//             position: absolute;
//             top: 0;
//             right: 190px;
//             background: rgba(white, .6);
//             z-index: 9999;
//         }
//         &.active {
//             display: block !important;
//         }
//         li {
//             display: block;
//             padding: 0;
//             margin-right: 0;
//             a {
//                 font-size: 16px;
//                 line-height: 19px;
//                 color: #7d939b;
//                 border: 0;
//                 display: block;
//                 text-align: left;
//                 padding: 12px 0 12px 16px;
//                 &:hover {
//                     color: #7d939b;
//                 }
//                 &.active {
//                     background: url(../img/misc/bottom.svg) left bottom repeat-x, url(../img/misc/top.svg) left top repeat-x, linear-gradient(to right, transparent 0%, #7d939b 0%, #7d939b 100%) 0 1px no-repeat;
//                     background-size: auto 10px, auto 10px, 100% calc(100% - 2px);
//                     &:after {
//                         display: none;
//                     }
//                 }
//             }
//         }
//     }
// }
// .menu-btn {
//     position: absolute;
//     margin: 0;
//     width: 23px;
//     height: 16px;
//     display: none;
//     border: 0;
//     top: 20px;
//     right: 20px;
//     z-index: 99999;
//     @include b(960) {
//         display: inline-block;
//     }
//     .line {
//         display: block;
//         width: 100%;
//         height: 2px;
//         background: #7d939b;
//         position: absolute;
//         transform-origin: center center;
//         transition: all .3s ease-in-out;
//         z-index: -9999px;
//         font-size: 0;
//         line-height: 0;
//         box-shadow: 0 0 4px 2px #fff;
//         &:first-of-type {
//             top: 0;
//         }
//         &:nth-of-type(2) {
//             top: 7px;
//         }
//         &:last-of-type {
//             top: 14px;
//         }
//     }
//     &.active {
//         position: fixed;
//         .line {
//             top: 50%;
//             left: 0;
//         }
//         .line:first-of-type {
//             transform: rotate(45deg);
//         }
//         .line:last-of-type {
//             transform: rotate(-45deg);
//         }
//         .line:nth-of-type(2) {
//             opacity: 0;
//             visibility: hidden;
//         }
//     }
//     &:hover {
//         opacity: .8;
//     }
// }