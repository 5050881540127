.dropdown {
	position:relative;
}
.js-dropdown {
	position:relative;
	&:after {
		display:block;
		position:absolute;
		content:"";
		width:0;
		height:0;
		border-top: 6px solid #000;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		right: -15px;
		top:50%;
		margin-top:-1px;
	}
	&.dropped {
		&:after {
			margin-top:-1px;
			border-bottom: 6px solid #000;
			border-top: 0;
		}
	}
}
.js-dropdown-cont {
	display:none;
	position:absolute;
	top:100%;
	left:0;
	z-index:9999;
}