.board {
	width:100%;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center center;
	padding: 100px 0;
	display:flex;
	flex-flow:column nowrap;
	justify-content:center;
	align-items:center;
	@include b(desktop){
		padding:75px 0;
	}
	&:first-child {		
		min-height: calc(100% - 135px);
	}
	&.auto{
		min-height: 0;
	}
	&__content {
		padding: 45px 0 65px;
		background: rgba(0, 0, 0, 0.21); 
		flex: 1 1 auto;
		max-width:100%;
	}
	&__title {
		text-align:center;
		padding:0 0 45px;
		margin:0;
		font-size:70px;
		line-height:1em;
		color:#fff;
		@include b(desktop){
			font-size:50px;    
			padding:0 0 15px   
		}
		@include b(mobile){
			font-size: 40px;
		}
	}
	&__actions {
		text-align:center;
		[class*="btn"]{
			display:inline-block;
			vertical-align:middle;
			margin-right:30px;
			min-width:194px;
			max-width:290px;
			width:auto;
			@include b(mobile){
				display:block;
				margin:0 auto 30px !important;
			}
			&:last-of-type{
				margin: 0;
			}
		}
		.social-btn{
			padding:8px 10px;
			white-space:nowrap;
			i {
				margin-right: 8px;
    			padding-right: 8px;
			}
		}
	}
}