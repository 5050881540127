.reviews__section{
    text-align: center;
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

.reviews__header{
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 24px;
}

.reviews__separator{
  border-bottom: 1px solid #a0c63f; 
  line-height:0.1em; 
  margin-top:40px;
  margin-bottom:40px;
  display: block;
}

.reviews__text{
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  text-align: left;
  margin-bottom:40px;
  p{
    line-height: 1.3;
    font-size: 16px;
    font-style: italic;
    & {
    	padding:6px 0 6px;
    }
  }
  .quote-icon{
    position: absolute;
    font-size: 27px;
    color:#a0c63f;
  }
  
  .quote-icon:first-child{
    top:-10px;
    left:0px;
    margin-right: 10px;
    
  }
  .quote-icon:last-child{
    bottom:-10px;
    right:0px;
  }
}


.reviews__features {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.reviews__img__wrap {
  padding: 10px;
  margin:0 70px;
  @include b(tablet){
	  margin: 0 15px
  }
  @include b(mobile){
	  width:100%;
	  img {
		margin: 0 auto
	  }
  }
}


