//----------------------------------------------------//
// TYPOGRAPHY                                         //
//----------------------------------------------------//
body {
	color: $mainFontColor;
	font-family: $mainFont;
	font-size: $mainFontSize;
	font-weight: $mainFontWeight;
	line-height: $mainFontHeight;
	@include b(desktop){
		font-size:13px;    
	}
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	font-weight:normal;
	line-height:normal;
}
h1 {
}
.page-title {
font-size: 50px;
line-height: 1.2em;
margin:0 auto;
padding:10px 0;
@include b(desktop){
	font-size:35px;
}
}
h2 {
	font-size: 24px;
	@include b(desktop){
		font-size:18px;
	}
}
h3 {
	font-size: 24px;
    line-height: 24px;
}
h4 {
    font-size: 18px;
    padding: 0 0 9px;
	@include b(desktop){
		font-size:15px;
	}
}
h5 {
	font-size: 14px;
    line-height: 16px;
}
h6 {
    font-size: 12px;
    line-height: 14px;
}
p {
    padding: 0;
    margin: 0;
    line-height: inherit;
    font-family: inherit;
    font-size:inherit;
    // & + & {
    // 	padding:6px 0 0;
    // }
}

hr {
	overflow: hidden;
	height: 1px;
	background: #e9e9e9;
	border: 0;
	margin: 32px 0;
}

q,
blockquote {
	text-align: center;
	display: block;
	font-style: italic;
}

strong,
b {
	font-weight: 600;
}

a:not([class*="btn"]) {	
	color: #000;
	transition: all .15s ease-in-out;
	text-decoration: none;
	border-bottom:0;
	&:hover {
		text-decoration: none; 
		color:#6138c2;
		border-bottom-color:transparent;
	}
}
a:active {}
a:visited {}

%ul {
	list-style:none;
	margin:0;
	padding:0;
	li {
		line-height:normal;
		font-size: inherit;
		line-height:inherit;
		display:block;
		padding: 0 0 0 16px;
		position:relative;
		&:before {
			content: '';
			display: block;
			position:absolute;
			width: 8px;
			height: 8px;
			top: 50%;
			transform:translateY(-50%);
			left:0;
			background: #000;
			border-radius: 50%;
			@include b(desktop){
				width: 5px;
				height: 5px;
		  }
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}

// ul {
// 	@extend %ul;
// 	&.native-bullets {		
// 		li {
// 			&:before {
// 				position:relative;
// 				left:auto;
// 				top:auto;
// 				transform:none;
// 				display: inline-block;
// 				vertical-align: middle;
// 				margin-right:8px;
// 			}
// 		}
// 	}
// }


%ol {
	list-style-type: decimal;
	list-style-position: inside;
	counter-reset: item;
	margin:24px 0;
	padding: 0;
	li {
		display: block;
		position: relative;
		line-height:inherit;
		font-size: inherit;
		padding:0 0 8px 0;
		&:before { 
			content: counters(item, ".") ". ";
			counter-increment: item;
			display: inline;
			vertical-align: top;
			font-weight: 800;
			color: #000;
			position:relative;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}

ol {
	@extend %ol;
}
.table-wrapper {
	overflow-x:auto;
	overflow-y:hidden;
}
table {
	width:100%;
	max-width:100%;
	border-collapse:collapse;
	border:0;
	table-layout:auto;
	margin: 0 auto;
	td, th {
		font-size:inherit;
		line-height:inherit;
		border:0;
		text-align:center;
		white-space:nowrap;
	}
	th {
		font-weight:400;
		font-family:$mainFont;		
		border-bottom: 1px solid #bdbdbd;
		font-size:24px;
		color:#000;
		vertical-align:top;
		padding:0 16px 16px;
		white-space:nowrap;
		&:first-child {
			border-right: 1px solid #bdbdbd;
			border-bottom:0;
			vertical-align:middle;
			padding:25px 16px 16px;
			text-align:right;
		}
		@at-root tr:first-child th:first-child {
			border-bottom:1px solid #bdbdbd;
			vertical-align:top;
			padding:0 16px 16px;
		}
		@include b(desktop){
			padding:0 8px 8px;
			font-size:18px;
			&:first-child {
				padding:12px 8px 8px;
			}

			@at-root tr:first-child th:first-child {
				padding:0 8px 8px;
			}
		}
	}
	td {
		color:#444;
		vertical-align:middle;
		padding:25px 16px 16px;
		font-size:14px;
		@include b(desktop){
			padding:12px 8px 8px;
		}
	}
	tr:last-child {
		td, th {
			padding-bottom:0;
			@include b(tablet){
				padding-bottom:8px;
			}
		}
	}
}


.upper {
	text-transform: uppercase;
}

.lower {
	text-transform: lowercase;
}

.c-dark, .c-black {
	color: default;
}
.c-white, .c-light {
	color: white;
}


.center {
	text-align:center;
}



img {
	display:block;
	max-width:100%;
}