#contactForm {
	border-radius:3px;
	&:after{		
		display:block;
		position:relative;
		padding: 15px 0;
	}
	&.fail {
		&:after {
			color:red;
			content:"Sorry. The form wasn't sent. Please try again later"
		}
	}
	&.sent {
		&:after {
			color:green;
			content:"Thank you. The form was sent."
		}
	}
}
.contacts__section {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 70px; }

.contacts__header {
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px; }

.contactus__address__section {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 40px;
    @include b(mobile) {
        justify-content: space-around;
    }
}
	
.contacts__row {
	display:flex;
	flex-flow:row nowrap;
	.contacts__col-2 {
		flex: 0 0 50%;
		padding:0 15px;
	}
	@include b(tablet){
		flex-wrap:wrap;
		.contacts__col-2 {
			flex: 1 1 100%;
			padding:0;
		}
	}
}
.contactus__img__wrap {
    border-radius: 5px;
    background-color: #ffffff;
	box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
}

.contactus__img__wrap > img{
    width:100%;
	@include b(tablet){
		display:inline-block;
		width:45%;
		vertical-align: middle;
	}
}

.contactus__logo{
    padding:30px;
	@include b(tablet){
		display:inline-block;
		width:45%;
		vertical-align: middle;
	}
}

.address__section {
    padding-top:20px;
    padding-left:30px;
    padding-right:20px;
    padding-bottom:43px;
    text-align: left;
	position: relative;
	[class*="fa"]{
		font-size:18px;
		margin-right:18px;
		color:#a0c63f;
		vertical-align:middle;
	}
    @include b(mobile){
        font-size:20px;
        padding-left:20px;
        padding-right:20px;
    }
    .location{
        font-size: 1.5em;
        font-weight: bold;
        display: block;
        @include b(desktop){
            font-size:22px;
        }
        @include b(mobile){
            font-size:20px;
        }
    }
	p a {
		&:hover {
			color: #a0c63f
		}
	}
    .city{
        padding-top:38px;
        color:#a0c63f;
        font-size:24px;
        text-transform: uppercase;
    }
    .index{
        padding-top:37px;
        padding-left:32px;
        font-size:24px;
    }
    .address{
        padding-top:24px;
        font-size:24px;
    }
    .phone{
        padding-top:39px;
        font-size:24px;
    }
    .email{
        padding-top:35px;
        font-size:24px;
        word-break: break-all;
    }
}

.social-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
	padding:0 20px 30px 30px;
    margin: 0 auto;
    a{
        color: #a0c63f;
        font-size:52px;
        text-align: center;
        &:hover{
			color: #000;
        }
    }
}

.form-group{
    padding-bottom:25px;
    text-align: left;

    label{
        color:#a0c63f;
        font-weight: bold;
        font-size:14px;
    }

    input{
        width:100%;
        font-size:16px;
        color: rgba(51, 51, 51, 0.5);
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
        border: solid 1px #959595;
        padding-left:9px;
        padding-top:9px;
        padding-bottom:5px;
    }

    select{
        width:100%;
        font-size:16px;
        color: rgba(51, 51, 51, 0.5);
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
        border: solid 1px #959595;
        padding-left:9px;
        padding-top:9px;
        padding-bottom:5px;
    }

    textarea{
        font-size:16px;
        width:100%;
        color: rgba(51, 51, 51, 0.5);
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
        border: solid 1px #959595;
        padding-left:9px;
        padding-top:9px;
        padding-bottom:5px;
        resize: none;
    }
}

.form-group.required{
    label::after{
        content:"*";
    }
}

.license__text{
    text-align: left;
    margin-bottom:38px;
}

.license__link__btn {
    color:#a0c63f;
}

.submit__btn{
    color: #fff;
    width: 213px;
    height: 35px;
    background-color: #a0c63f;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    font-size:18px;
    font-weight: bold;
}