
video,iframe{
  width: 100%;
  height: auto;
  display:block;
}
figure {
	margin: 1em auto;
	width:820px;
	max-width:100%;
}

.video{
	&-wrapper{
		position:relative;
	}
	&-overlay{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color:rgba(0,0,0,.5);
		background-size:cover;
		background-repeat:no-repeat;
		background-position:center;
		z-index:9;
		transition:all 1s ease;
		cursor:pointer;
		&:hover {
			.video-play-btn{
				background:rgba(0,0,0,.5);
			}
		}		
		&:active{
			.video-play-btn{				
				box-shadow:0 0 50px 15px #fff;
			}
		}
		&--hidden {
			opacity:0;
			visibility:hidden;
		}
	}
	&-play-btn{
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    border-radius: 50%;
	    width: 132px;
	    height: 132px;
	    background: rgba(0,0,0,0.2);
	    border: 7px solid #fff;
	    transition: all .3s ease-in-out;
		&:before {
		    content: "";
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    margin-left: 6px;
		    transform: translate(-50%, -50%);
		    border-style: solid;
		    border-width: 36px 0 36px 54px;
		    border-color: transparent transparent transparent #fff;
		    width: 0;
		    height: 0;
		    transition: all .3s ease-in-out;
		}
		@include b(desktop){
			width:107px;
			height:107px;
			&:before {
		    	border-width: 29px 0 29px 43px;				
			}
		}
		@include b(767){
			width:39px;
			height:39px;
			border-width:3px;
			&:before {
		    	border-width: 10px 0 10px 16px;		
		    	margin-left:2px;		
			}
		}
	}
}