//----------------------------------------------------//
// Mixins & Includes                                  //
//----------------------------------------------------//
@mixin notext {
    overflow: hidden;
    text-indent: -9999px;
}

.clearfix:after {
    content: "";
    clear: both;
}

.centered {
    display: table;
    width: 100%;
    height: 100%;
    >* {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
}

// PLACEHOLDER MIXIN
@mixin placeholder($input-text-color, $input-font-weight:400, $input-font-family:$mainFont) {
    &::-webkit-input-placeholder {
        color: $input-text-color;
        font-weight: $input-font-weight;
        font-family: $input-font-family;
        transition: all .15s ease-in-out;
    }
    &:-moz-placeholder {
        color: $input-text-color;
        opacity: 1;
        font-weight: $input-font-weight;
        font-family: $input-font-family;
        transition: all .15s ease-in-out;
    }
    &::-moz-placeholder {
        color: $input-text-color;
        opacity: 1;
        font-weight: $input-font-weight;
        font-family: $input-font-family;
        transition: all .15s ease-in-out;
    }
    &:-ms-input-placeholder {
        color: $input-text-color;
        font-weight: $input-font-weight;
        font-family: $input-font-family;
        transition: all .15s ease-in-out;
    }
    &:focus:-moz-placeholder {
        color: transparent;
        opacity: 1;
    }
    &:focus::-moz-placeholder {
        color: transparent;
        opacity: 1;
    }
    &:focus::-webkit-input-placeholder {
        color: transparent;
    }
    &:focus:-ms-input-placeholder {
        color: transparent;
    }
    &:focus::placeholder {
        color: transparent;
    }
}

@mixin button($color) {
    box-shadow: 0 0 0 5px $color inset;
    color: $color;
    &:before,
    &:after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        border: 5px solid transparent;
        width: 0;
        height: 0;
    }
    &.btn--smaller {
        box-shadow: 0 0 0 4px $color inset;
        &:before,
        &:after {
            border: 4px solid transparent;
        }
    }
    &::before {
        top: 0;
        left: 0;
    }
    &::after {
        bottom: 0;
        right: 0;
    }
    &:hover {
        box-shadow: none;
    }
    &:hover::before,
    &:hover::after {
        width: 100%;
        height: 100%;
    }
    &:hover::before {
        border-top-color: $color; // Make borders visible
        border-right-color: $color;
        transition: width 0.25s ease-out, // Width expands first
        height 0.25s ease-out 0.25s; // And then height
    }
    &:hover::after {
        border-bottom-color: $color; // Make borders visible
        border-left-color: $color;
        transition: border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
        width 0.25s ease-out 0.5s, // And then exanding width
        height 0.25s ease-out 0.75s; // And finally height
    }
}

@mixin clearfix {
    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}

// Clear select without default button
@mixin clearSelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

//elements get appended with "__" and the $name
@mixin e($name) {
    @at-root #{&}__#{$name} {
        @content;
    }
}

//modifiers get appended with "--" and the $name
@mixin m($name) {
    @at-root #{&}--#{$name} {
        @content;
    }
}

@mixin b($point) {
    @if $point==desktop {
        /* ----------- Non-Retina Screens ----------- */
        @media screen  and (max-device-width: 1600px),  (max-width: 1600px) {
            @content ;
        }
        /* ----------- Retina Screens ----------- */
        // @media screen  and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
        //     @content ;
        // }
    }
    @else if $point==tablet {
        @media (max-width: 1023px) {
            @content ;
        }
    }
    @else if $point==phablet {
        @media (min-width: 37.5em) {
            @content ;
        }
    }
    @else if $point==mobile {
        @media (max-width: 767px) {
            @content ;
        }
    }
    @else if $point==min {
        @media (max-width: 479px) {
            @content ;
        }
    }
    @else if $point==mobile3x {
        @media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min-resolution: 3dppx), only screen and (min-resolution: 350dpi) {
            @content ;
        }
    }
    @else {
        @media (max-width: $point + 'px') {
            @content ;
        }
    }
}

@mixin before-img($width, $height) {
    content: '';
    display: block;
    position: absolute;
    width: $width;
    height: $height;
}

//----------------------------------------------------//
// REM FONT_SIZE WITH FALLBACK 												//
//----------------------------------------------------//
// USAGE:
// p {
//   @include font-size(14px)
// }
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}