.home__links__wrapper{
   background-color: #fff;
}

.home__links__section{
    text-align: center;
    padding-top: 65px;
	padding-bottom: 45px;
	@include b(mobile){
		padding-top: 30px;
		padding-bottom: 30px;

	}
  }

  .home__links__features {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    @include b(1150) {
        justify-content: space-around;
    }
}

.home__links__img__wrap {
    padding:10px;
    width: 166px;
    height:156px;
    margin-top:10px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
	line-height: normal;
	@include b(min) {
		margin-left: auto;
		margin-right: auto;
	}
}



.home__links__img__description {
    margin-top: 12px;
    text-align: center;
    position: relative;
}

.home__links__img__description .title {
    font-size: 1.5em;
    font-size: 16px;
    font-weight: bold;
    display: block;
    @include b(desktop){
        font-size:22px
    }
    @include b(mobile){
        text-align:center;
        display:block;
        &:after {
            margin:10px auto;
        }
    }
}

.home__icon{
    width:55px;
    height:60px;
    margin-left:30%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    @include b(desktop){
        margin-left:40%;
    }
    @include b(tablet){
        margin-left:40%;
    }
    @include b(mobile){
        margin-left:40%;
    }
    @include b(min){
        margin-left:40%;
    }
}

.start__icon{
    background-image: url('../img/home/start_icon.png');
}

.implementation__icon{
    background-image: url('../img/home/implementation_icon.png');
}

.years__icon{
    background-image: url('../img/home/years_icon.png');
}

.support__icon{
    background-image: url('../img/home/support_icon.png');
}

.offshore__icon{
    background-image: url('../img/home/offshore_icon.png');
}

// .home__links__img__wrap:hover{
//     background-color: #a0c63f;
//     color:#fff;
// }

// .home__links__img__wrap:hover .start__icon{
//     background-image: url('../img/home/start_hover_icon.png');
// }

// .home__links__img__wrap:hover .implementation__icon{
//     background-image: url('../img/home/implementation_hover_icon.png');
// }

// .home__links__img__wrap:hover .years__icon{
//     background-image: url('../img/home/years_hover_icon.png');
// }

// .home__links__img__wrap:hover .support__icon{
//     background-image: url('../img/home/support_hover_icon.png');
// }

// .home__links__img__wrap:hover .offshore__icon{
//     background-image: url('../img/home/offshore_hover_icon.png');
// }