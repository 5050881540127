.history__section__wrapper{
  background-color: #fff;
}

.history__section{
    text-align: center;
    padding-top: 20px;
	padding-bottom: 70px;
	@include b(tablet){
		padding: 20px 0 0;
	}
  }

  .history__separator{
    border-bottom: 1px solid #a0c63f; 
    line-height:0.1em; 
    margin-top:40px;
    margin-bottom:40px;
    display: block;
  }

  .history__header{
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
  }