//----------------------------------------------------//
// Variables                                          //
//----------------------------------------------------//
$mainFont:			'Avenir Next','Times New Roman', serif; 
$mainFontSize:		16px;
$mainFontHeight:	24px;
$mainFontWeight:	normal;
$mainFontColor:		#000;

// Variables => Colors
//----------------------------------------------------//
$default:$mainFontColor;
$themeColor: #5f24e8;
