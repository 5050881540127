.expertise__section__wrapper{
  background-color: #f9f9fa;
}

.expertise__section{
    text-align: center;
    padding-top: 20px;
	padding-bottom: 70px;
	@include b(mobile){
		
	padding-bottom: 30px;
	}
  }

  .expertise__separator{
    border-bottom: 1px solid #a0c63f; 
    line-height:0.1em; 
    margin-top:40px;
    margin-bottom:40px;
    display: block;
  }

  .expertise__header{
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
	font-size: 24px;
	@include b(mobile){
		margin: 40px 0 10px
	}
  }