button,
.btn {
	text-decoration: none;
	transition:all .15s ease-in-out;
	appearance:none;
	outline:0;
	border:0;
    &:hover {
        text-decoration: none;
    }
    &:hover,
    &:focus {
        text-decoration: none;
    }
    &--primary {
        @extend .btn;
        text-transform: uppercase;
        background-color: #a0c63f;
        color: #fff;
        padding: 10px 22px;
        font-size: 18px;
        border-radius: 5px;
        font-weight: bold;
        &:hover {
            background-color: #f9f9fa;
            box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
            color: #a0c63f;
        }
    }
}