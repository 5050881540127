.about__info__block{
    width:457px;
    height:341px;
    background: #fff;
    padding-left:30px;
    padding-top:35px;
    padding-right:50px;
    margin-top:75px;
    margin-left:100px;
	border-radius: 5px;
	@include b(mobile){		
		width:auto;
		height:auto;
		margin-top:15px;
		margin-left:0;
		padding-left:15px;
		padding-top:25px;
		padding-right:15px;
		padding-bottom:15px;
	}
  }