.home__board-carousel {
    position: relative;
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
		z-index: 999;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: rgba(#fff,.3);
		cursor:pointer;
		transition:all .15s ease-in-out;
		&:hover {
			background: rgba(#fff, .5);
		}
		&:before {
			content:"";
			display:block;
			position: absolute;
			top:12px;
			border-color:#333;
			border-style:solid;
			width: 16px;
			height: 16px;
		}	
        &.slick-prev {
            left: 45px;
			&:before{
				transform:rotate(45deg);
				border-width: 0 0 3px 3px;
				left:15px;
			}
        }
        &.slick-next {
            right: 45px;
			&:before{
				transform:rotate(-45deg);
				border-width: 0 3px 3px 0;
				left:10px;
			}
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
		margin: 0;
		z-index:999;
		@include b(min){
			bottom:5px;
		}
        li {
            list-style: none;
            font-size: 0;
            line-height: 0;
            display: inline-block;
            margin: 0 2px;
            button {
                appearance: none;
                outline: 0;
                background-color: transparent;
                border: solid 5px rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                width: 20px;
                height: 20px;
				cursor: pointer;
				&:hover {
					background:rgba(#fff, .5)
				}
			}
			&.slick-active button {				
				background:rgba(#fff, .5)
			}
        }
    }
}

.home__info__block {
    width: 457px;
    height: 341px;
    background: #fff;
    padding-left: 30px;
    padding-top: 65px;
    padding-right: 50px;
    margin-top: 75px;
    margin-left: 100px;
    border-radius: 5px;
    @include b(mobile) {
        width: auto;
        height: auto;
        margin-top: 15px;
        margin-left: 0;
        padding-left: 15px;
        padding-top: 25px;
        padding-right: 15px;
        padding-bottom: 15px;
    }
}

.home__title {
    text-align: left;
    font-size: 24px;
    line-height: normal;
    color: #000;
    text-transform: uppercase;
    @include b(desktop) {
        font-size: 24px;
    }
}

.home__separator {
    border-bottom: 1px solid #a0c63f;
    line-height: 0.1em;
    margin-top: 40px;
    margin-bottom: 40px;
    display: block;
}

.home__sub__title {
    text-align: left;
    font-size: 16px;
    line-height: normal;
    color: #000;
    @include b(desktop) {
        font-size: 16px;
    }
}

.home__header {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
    @include b(desktop) {
        font-size: 33px;
        margin-top: 25px;
        margin-bottom: 17px;
    }
}