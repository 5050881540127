.footer {
    position: relative;
    height: 85px;
    background:#363636;
    padding: 31px 0 41px;
	@include b(mobile){
		height:auto;
		text-align:center;
	}
    small {
        font-size:12px;
        color:#fff;
    }
    &__nav {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        &-col {
            flex: 0 0 auto;
            &--wide {
                flex-basis: 240px;
                width:240px;
            }
        }
        @include b(mobile){
            display:block;
            &-col {
                flex:1 0 100%;
                width:100%;
                text-align:center;
                & + & {
                    margin-top:15px;
                }
            }
		}
		ul {
			list-style-type: none;
			text-align: center;
			margin: 0;
			padding: 0;
			display:flex;
			li {
				padding: 0;
				margin: 0;
				margin-right: 33px;
				&:last-of-type{
					margin-right: 0;
				}
				&:before {
					display: none;
				}
				a {
					color: #fff;
					font-size:16px;
					@include b(desktop){
						font-size:15px;    
					}
					&:hover {
						color: #a0c63f;
					}
				}
			}
			@include b(mobile){
				justify-content: center;
				li {
					
				margin-right: 15px;
				}
			}
		}
    }
    
}