.leadership__wrapper{
    background-color: #f9f9fa;
}

.leadership__section{
    text-align: center;
	padding-bottom: 60px;
	@include b(tablet){
		padding-bottom: 10px;
	}
}

.leadership__header{
    text-transform: uppercase;
    margin-top: 60px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
}

.leadership__features {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
    margin: 0 -15px;
    @include b(1150) {
        justify-content: space-around;
    }
}

.leadership__img__wrap {
    width: 458px;
    margin-top:20px;
    margin-left:15px;
    margin-right:15px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(37, 37, 37, 0.2);
}


.leadership__img__description {
    padding:22px;
    text-align: left;
    position: relative;
}

.leadership__img__description .title {
    font-size: 1.5em;
    display: block;
    @include b(desktop){
        font-size:22px
    }
    @include b(mobile){
        text-align:center;
        display:block;
        &:after {
            margin:10px auto;
        }
    }
}
