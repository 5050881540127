.home__services__wrapper {
    background-image: url("../img/home/services.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home__services__section {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 70px;
}

.home__services__header {
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
}

.home__services__features {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 40px;
    @include b(1150) {
        justify-content: space-around;
    }
}

.home__services__block {
    width: 262px;
    min-height: 452px;
    margin: 15px 40px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(37, 37, 37, 0.03);
    @include b(1100) {
        min-height: 0;
		padding: 0 0 15px;
		margin:15px;
    }
    @include b(min) {
        margin: 15px auto;
    }
}

.home__services__img__description {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 30px;
    text-align: left;
    position: relative;
    @include b(tablet) {
        padding: 15px !important;
    }
}

.home__services__img__description .title {
    font-size: 1.5em;
    padding-bottom: 13px;
    font-weight: bold;
    display: block;
    @include b(desktop) {
        font-size: 22px;
    }
    @include b(mobile) {
        text-align: center;
        display: block;
        &:after {
            margin: 10px auto;
        }
    }
}

.read__more__btn {
    margin-top: 20px;
    text-decoration: none;
    color: #a0c63f;
    font-size: 18px;
    font-weight: bold;
	float: left;
	transition:all .15s ease-in-out;
	&:hover {
		color:black
	}
}

.read__more__btn::after {
    content: '→';
    margin-left: 10px;
}


.home__services__img__wrap {
    position: relative;
    display: block;
    .home__services__img__wrap-content,
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        img {
            display: none;
        }
    }
    &:hover {
        .home__services__img__wrap-content {
            display: flex;
            justify-content: center;
            align-items: center;
            .overlay {
				display: flex;
				justify-content: center;
				align-items: center;
                opacity: .5;
                background-color: #a0c63f;
                border-radius: 5px 5px 0 0;
            }
            img {
                z-index: 2;
                display: block;
            }
        }
    }
}