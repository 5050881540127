.popup {
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	min-width:60%;
	max-width:95%;
	max-height:95%;
	display:none;
	background:#fff;
	padding: 40px 50px 50px;
	overflow-x:hidden;
	overflow-y:auto;
	-webkit-overflow-scrolling: touch;
	&>*{		
		-webkit-transform: translateZ(0px);
	}
	&.gutter {
		padding: 40px 195px 50px;
	}
	&.narrow {
		min-width:0;
		width:555px;
		@include b(desktop){
			width:400px;
		}
	}
	.main__form__container {
		padding:0;
		margin:0 auto;
	}
	@include b(desktop){
		padding:25px 15px 25px;
		&.gutter {
			padding:25px 15px 25px;
		}
	}
	&-wrapper {
		position:fixed;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background: rgba(#141223,.81);
		z-index:9999;
	}
	
    &__heading {
        text-align:center;
        font-size: 50px;
        padding:0 0 24px;
        margin: 0 0 20px;
        position: relative;
		@include b(desktop){
			font-size:28px;    
		}
		@include b(tablet){
			font-size: 36px;
			padding:0 0 15px;
			margin: 0 0 15px;
		}
        &:before {
            content:"";
            position:absolute;
            width:90px;
            display:block;
            height:1px;
            background: #000;
            left:50%;
            bottom:1px;
            transform:translateX(-50%);
		}
		&--no-line {
			&:before {
				display:none;
			}
		}
    }
    &__content{
        font-size:19px;
    }
    &__action{
        margin: 30px 0 0;
    }
}
.popup-wrapper > .btn--close {
	position: absolute;
	top: 12px;
	right:20px;
}
.popup > .btn--close {
	position: absolute;
	top: 12px;
	right:20px;
}