.customers__section{
    text-align: center;
    background-color: #fff;
    padding-top: 20px;
	padding-bottom: 70px;
	@include b(mobile){
		padding-bottom: 100px
	}
  }

.customers__header{
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  font-size: 24px;
}

.customers__separator{
  border-bottom: 1px solid #a0c63f; 
  line-height:0.1em; 
  margin-top:40px;
  margin-bottom:40px;
  display: block;
}

.customers__text{
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  text-align: left;
  margin-bottom:40px;
  p{
    line-height: 1.3;
    font-size: 16px;
    font-style: italic;
    & {
    	padding:6px 0 6px;
    }
  }
  .quote-icon{
    position: absolute;
    font-size: 27px;
    color:#a0c63f;
  }
  
  .quote-icon:first-child{
    top:-10px;
    left:0px;
    margin-right: 10px;
    
  }
  .quote-icon:last-child{
    bottom:-10px;
    right:0px;
  }
}

.visit__link__btn{
  text-decoration: none;
  color:#a0c63f;
  font-size:18px;
  font-weight: bold;
  float: left;
  transition:all .15s ease-in-out;
  &:hover {
	  color:black;
  }
  @include b(mobile){
	  position:absolute;
	left:15px;
	margin-top:2em
  }
}

.visit__link__btn::after{
  content: '→';
  margin-left: 10px;
}

.support__link__btn{
  float: right;
  text-decoration: none;
  color:#a0c63f;
  font-size:18px;
  font-weight: normal;
}

.customers__slider{
  height:40px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.prev__btn{
  opacity: 0.3;
  color:#333;
  font-size: 32px;
  background:transparent;
  border: none;
  position: absolute;
  left:0px;
}

.next__btn{
  opacity: 0.3;
  color:#333;
  font-size: 32px;
  background:transparent;
  border: none;
  position: absolute;
  right:0px;
}