.home__board {
    padding: 150px 0;
    @include b(desktop) {
        padding: 110px 0;
    }
    @include b(min){
        padding:35px 0;
    }
}



