.services__wrapper{
    background: #fff;
}

.services__section{
    text-align: center;
    padding-top: 20px;
	padding-bottom: 70px;
	@include b(mobile){
		padding-bottom: 0;
	}
  }

  .services__header{
    text-transform: uppercase;
    padding-top: 40px;
    text-align: center;
    font-size: 24px;
}

.services__features {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    @include b(1150) {
        justify-content: space-around;
	}
    @include b(mobile) {
        padding: 20px 0 0
	}
	
}

.service__block {
    width: 945px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(37, 37, 37, 0.05);
}

.services__img__wrap{
    position: relative;
    div{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.services__features:nth-child(even) {background-color: #ffffff;}
.services__features:nth-child(odd) {background-color: #f9f9fa;}

.services__img__description {
    padding-top:20px;
    padding-left:30px;
    padding-right:40px;
    padding-bottom:30px;
    text-align: left;
    position: relative;
}

.services__img__description .title {
    font-size: 1.5em;
    padding-bottom:13px;
    font-weight: bold;
    display: block;
    @include b(desktop){
        font-size:22px;
    }
    @include b(mobile){
        text-align:center;
        display:block;
        &:after {
            margin:10px auto;
        }
    }
}

.services__list{
    list-style-type: none;
    padding-left:9px;
    li{
        .check-icon{
            margin-right:10px;
            color:#a0c63f;
        }
    }
    
}